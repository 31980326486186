<template>
    <div class="staff-landing">
        <HeroBasic
            :heading="title"
            accentColor="grey"
            :richText="dek"
            class="page-heading"
            :showTopographic="true"
            :hasHeaderOffset="true"
        />

        <client-only>
            <search
                :page-language="pageLanguage"
                :index-name="`people_${pageLanguage}`"
                :filter="`isArchived != TRUE AND personDetails.personType = staff AND personDetails.hideFromStaffListing != TRUE AND center = ${center}`"
                :has-filters="false"
                :enabledFilters="{ topics: false, regions: false, years: false, experts: false }"
                :limit="500"
                disable-syndication-filter
            >
                <template #results="{ results }">
                    <template v-for="(result, index) in results">
                        <div v-if="result" class="result" :key="index">
                            <div class="person">
                                <Anchor v-if="result.hasProfile" :url="result.url" class="details">
                                    <Typography as="h4" variant="h4-display" class="title">{{ result.title }} </Typography>
                                </Anchor>
                                <Typography v-else as="h4" variant="h4-display">{{ result.title }} </Typography>
                                <Typography as="div" variant="body-display-small-italic" class="role"
                                    >{{ result.personTitle }}
                                </Typography>
                            </div>
                        </div>
                    </template>
                </template>
            </search>
        </client-only>
    </div>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();

defineProps({
    title: {
        type: String,
        default: '',
    },
    dek: {
        type: Object,
        default: () => {},
    },
    contentType: {
        type: String,
        default: '',
    },
    id: {
        type: String,
    },
});

const renderedFilters = [];

const { currentCenter } = useCenters();
const center = currentCenter.value?.id;

const headerAttributesStore = useHeaderAttributesStore();
onMounted(() => {
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.page-heading {
    background: color(grey, light);
    border-bottom: 0.1rem solid palette(border-light);
}

.result {
    padding-top: vertical-space(1);
    padding-bottom: vertical-space(1);
    align-items: center;

    @include grid;
    @include content-area;

    @include media-query(phone) {
        display: block;
    }

    &:not(:last-child) {
        border-bottom: 1px solid palette(bg-gray-dark);
    }

    .person {
        grid-column: 1 / span 8;
        display: block;
        gap: vertical-space(1);
        align-items: center;

        @include media-query(phone) {
            margin-bottom: vertical-space(1);
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        .details {
            flex-grow: 1;
        }

        .title {
            color: color(blue, default, 0.8);
        }

        .role {
            color: color(noir, default, 0.6);
        }
    }

    .features {
        display: none;

        @include media-query(full) {
            display: block;
            grid-column: 7 / span 3;
            @include padding-tb(vertical-space(1));
        }
    }
}
</style>
